import * as React from "react";
import SiteWrapper from '../components/siteWrapper';
import Neuigkeiten from '../components/news/allNews';

const NeuigkeitenPage = (props) => (
  <SiteWrapper title="Neuigkeiten - SV Kappel" description="Hier findest du alle Neugikeiten rund um den SV Kappel.">
    <Neuigkeiten />
  </SiteWrapper>
)

export default NeuigkeitenPage;
