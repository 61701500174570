import styled from 'styled-components';
import { Link } from 'gatsby';

const BigText = styled.p`
    font-family: ${props => props.theme.fonts.heading};
    font-weight: 600;
    font-size: 1.8em;
    color: ${props => props.theme.colors.black};
    border-left: solid 7px ${props => props.theme.colors.accent};
    padding: 10px;
    margin: 10px 0;
`

const LinkButton = styled(Link)`
    padding: 15px 20px;
    border: solid 1px ${props => props.theme.colors.accent};
    color: ${props => props.theme.colors.accent};
    font-family: ${props => props.theme.fonts.heading};
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-size: 1.3em;
    font-weight: 600;
    &:hover {
        color: #fff;
        background: ${props => props.theme.colors.accent};
    }
`
const Button = styled.div`
    padding: 15px 20px;
    cursor: pointer;
    border: solid 1px ${props => props.theme.colors.accent};
    color: ${props => props.theme.colors.accent};
    font-family: ${props => props.theme.fonts.heading};
    text-transform: uppercase;
    transition: all 0.2s ease-in-out;
    text-decoration: none;
    font-size: 1.3em;
    font-weight: 600;
    &:hover {
        color: #fff;
        background: ${props => props.theme.colors.accent};
    }
`


export { BigText, LinkButton, Button };