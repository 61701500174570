import React from 'react';
import styled from 'styled-components';
import { Time as _Time } from 'styled-icons/boxicons-regular/';
import { PlusCircle } from 'styled-icons/boxicons-solid/';
import { Link } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

const CardContent = styled.section`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 15px;
    transition: transform 0.4s ease;
    background: linear-gradient(
        hsl(0 0% 0% / 0),
        hsl(0 0% 0% / 0.6) 50%,
        hsl(0 0% 0% / 0.95)
    );
`;
const Container = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: -7px;
    overflow: hidden;
`;
const Plus = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(0);
    transition: transform 0.4s ease;
`;
const SPlus = styled(PlusCircle)`
    color: rgba(255, 255, 255, 0.7);
`;

const Wrapper = styled(Link)`
    display: flex;
    flex: 1;
    flex-direction: column;
    margin-top: 15px;
    overflow: hidden;
    cursor: pointer;
    text-decoration: none;
    color: ${(props) => props.theme.colors.white};
    transition: transform 0.4s ease;
    &:hover ${CardContent} {
        transform: translateY(70%);
    }
    &:hover ${Plus} {
        transform: scale(1) translateY(0);
        transition-delay: 0.2s;
    }
    &:hover {
        transform: scale(1.05);
    }
`;

const Text = styled.h1`
    font-family: ${(props) => props.theme.fonts.heading};
    font-weight: 600;
    font-size: clamp(1.2em, 1.8vw, 1.4em);
    margin-bottom: 0;
    padding-bottom: 5px;
`;
const Excerpt = styled.p``;

const ExcerptWrapper = styled.div`
    padding: 20px;
    flex: 1;
    border-right: solid 1px ${(props) => props.theme.colors.gray2};
    border-left: solid 1px ${(props) => props.theme.colors.gray2};
    border-bottom: solid 1px ${(props) => props.theme.colors.gray2};
    color: ${(props) => props.theme.colors.darkGray};
`;

const Time = styled.div`
    font-size: 0.9em;
    margin-bottom: 10px;
    color: ${(props) => props.theme.colors.white};
`;
const STime = styled(_Time)`
    color: ${(props) => props.theme.colors.gray2};
`;
const Footer = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Wrap = styled.div``;

const Stellenanzeige = styled.div`
    background: ${({ theme }) => theme.colors.accent};
    padding: 2px 5px;
`;

const NewsTile = ({ src, children, time, slug, excerpt, stellenanzeige }) => (
    <Wrapper to={slug} asModal state={{ modal: true }}>
        <Container>
            <GatsbyImage image={getImage(src)} alt={children} />
            <CardContent>
                <Text>{children}</Text>
                <Footer>
                    <Time>
                        <STime size={24} /> {time}
                    </Time>
                    <Wrap>
                        {stellenanzeige && (
                            <Stellenanzeige>Stellenanzeige</Stellenanzeige>
                        )}
                    </Wrap>
                </Footer>
            </CardContent>
            <Plus>
                <SPlus size={100} />
            </Plus>
        </Container>
        {excerpt && (
            <ExcerptWrapper>
                <Excerpt>{excerpt}</Excerpt>
            </ExcerptWrapper>
        )}
    </Wrapper>
);

export default NewsTile;
