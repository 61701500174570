import React from 'react';
import styled from 'styled-components';
import { useStaticQuery, graphql } from 'gatsby';
import { BigText } from '../common';
import NewsTile from './newsTile';

const Wrapper = styled.section`
    width: 100%;
    padding: 20px;
    max-width: 1200px;
    margin: auto;
`;
const NewsWrapper = styled.div`
    display: grid;
    grid-gap: 30px;
    grid-template-columns: auto auto auto;
    @media (max-width: 860px) {
        grid-template-columns: auto auto;
    }
    @media (max-width: 650px) {
        grid-template-columns: auto;
    }
`;

const News = ({ limit }) => {
    const data = useStaticQuery(graphql`
        query AllNewsQuery {
            allFile(
                filter: { sourceInstanceName: { eq: "news" } }
                sort: {
                    fields: childrenMarkdownRemark___frontmatter___datum
                    order: DESC
                }
            ) {
                edges {
                    node {
                        childMarkdownRemark {
                            fields {
                                slug
                            }
                            excerpt
                            html
                            frontmatter {
                                beitragsbild {
                                    childImageSharp {
                                        gatsbyImageData(aspectRatio: 1.666)
                                    }
                                }
                                title
                                datum(formatString: "DD.MM.YYYY")
                                stellenanzeige
                            }
                        }
                    }
                }
            }
        }
    `);

    const renderNews = () => {
        const edges = data.allFile.edges;
        return edges.map((edge) => (
            <NewsTile
                src={edge.node.childMarkdownRemark.frontmatter.beitragsbild}
                slug={edge.node.childMarkdownRemark.fields.slug}
                time={edge.node.childMarkdownRemark.frontmatter.datum}
                key={edge.node.childMarkdownRemark.fields.slug}
                excerpt={edge.node.childMarkdownRemark.excerpt}
                stellenanzeige={
                    edge.node.childMarkdownRemark.frontmatter.stellenanzeige
                }
            >
                {edge.node.childMarkdownRemark.frontmatter.title}
            </NewsTile>
        ));
    };

    return (
        <Wrapper>
            <BigText>Neuigkeiten</BigText>
            <NewsWrapper>{renderNews()}</NewsWrapper>
        </Wrapper>
    );
};

export default News;
